<template>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessSupportDetails"
      v-if="business.support_detail != undefined"
      class="space-y-5"
    >
      <app-form-input
        id="business.support_detail.statement_descriptor"
        name="business.support_detail.statement_descriptor"
        type="text"
        :placeholder="$t('business.support_detail.business_name_placeholder')"
        :labelText="$t('business.support_detail.statement_descriptor')"
        v-model="business.support_detail.statement_descriptor"
        :errorMessage="errors.statement_descriptor"
        :disabled="editMode"
      />

      <app-form-input
        id="business.support_detail.short_descriptor"
        name="business.support_detail.short_descriptor"
        type="text"
        :placeholder="$t('business.support_detail.business_placeholder')"
        :labelText="$t('business.support_detail.shortened_descriptor')"
        v-model="business.support_detail.short_descriptor"
        :errorMessage="errors.short_descriptor"
        :disabled="editMode"
      />

      <app-form-input
        id="business.support_detail.support_email"
        name="business.support_detail.support_email"
        type="text"
        :placeholder="$t('business.support_detail.email')"
        :labelText="$t('business.support_detail.support_email')"
        v-model="business.support_detail.support_email"
        :errorMessage="errors.support_email"
        :disabled="editMode"
      />

      <app-form-telephone-no
        name="business.support_detail.support_tel_no"
        :labelText="$t('business.support_detail.phone_no')"
        class="pb-5"
        v-model="business.support_detail.support_tel_no"
        :errorMessage="errors.support_tel_no"
        :disabled="editMode"
      />

      <app-button
        type="submit"
        v-if="!editMode"
        class="mt-3 w-full"
        :showf70Icon="false"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      emptyErrors: {
        statement_descriptor: [],
        short_descriptor: [],
        support_tel_no: [],
        support_email: [],
      },
    };
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },

  methods: {
    async submitBusinessSupportDetails() {
      this.resetState();

      this.business.support_detail.business_id = this.business.id;

      const businessSupportDetails = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessSupportDetails",
        this.business.support_detail
      );

      if (businessSupportDetails != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          5000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;
      
      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>